import React, { useState, useEffect } from 'react';
import { getCurrentUser, updatePassword } from 'aws-amplify/auth';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { addToast } = useToast();

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            addToast(`Error modifying user data: [New password and confirm password do not match.] Contact support@scadaswarm.io if this error persists.`, {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
            return;
        }

        try {
            await updatePassword({oldPassword: currentPassword, newPassword: newPassword});
            addToast('Password updated successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success'
            });
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            addToast(`Error modifying user data: [${error.message}] Contact support@scadaswarm.io if this error persists.`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error updating password:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleChangePassword();
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">Change Password</Card.Title>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formCurrentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formConfirmPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Change Password
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ChangePassword;
