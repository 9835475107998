import React from "react";
import { Switch, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { onChangeDarkMode, onChangeHighcontrast, onModalOpen } from '../Redux/Actions/Actions';
import AuthHeader from "../Component/Common/AuthHeader";
import Login from "../Component/Auth/SignIn";
import SettingsModal from "../Component/Common/SettingsModal";
import ErrorPage from "../Component/Auth/ErrorPage";
import FlaresoftLogo from "../assets/images/base_logo_transparent_background.png";

function AuthIndex(props) {
    const { modalopen, darkMode, highcontrast } = props.Mainreducer;

    return (
        <div className="auth-main p-2 py-3 p-xl-4">
            <AuthHeader
                onModalOpen={(val) => {
                    props.onModalOpen(true)
                }}
            />
            <SettingsModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => {
                    props.onModalOpen(false)
                }}
                onChangeDarkMode={() => {
                    props.onChangeDarkMode(darkMode === 'dark' ? 'light' : 'dark')
                }}
                onChangeHighcontrast={() => {
                    props.onChangeHighcontrast(highcontrast === 'high-contrast' ? 'light' : 'high-contrast')
                }}
            />
            <Routes>
                <Route exact path='/' element={<Login />} />
                <Route path='*' element={<ErrorPage />} />
            </Routes>

            {/* Support message */}
            <div className="text-center mt-4">
                <span>Need help? Visit our <a href="https://support.flaresoft.solutions/support/solutions"
                                              target="_blank" rel="noopener noreferrer" className="primary-link">support page</a> or email us at <a
                    href="mailto:woodland.support@flaresoft.solutions"
                    className="primary-link">woodland.support@flaresoft.solutions</a>.</span>
            </div>

            {/* Footer */}
            <footer className="footer">
                <div className="container text-center">
                    <hr className="custom-separator"/>
                    <p>Powered by Flaresoft Solutions Inc.</p>
                    <a href="https://www.flaresoft.solutions" target="_blank" rel="noopener noreferrer">
                        <img className="flaresoft-logo" src={FlaresoftLogo} alt="Flaresoft Solutions"/>
                    </a>
                </div>
            </footer>
        </div>
    );
}

const mapStateToProps = ({ Mainreducer }) => ({
    Mainreducer
});

export default connect(mapStateToProps, {
    onModalOpen,
    onChangeDarkMode,
    onChangeHighcontrast
})(AuthIndex);
