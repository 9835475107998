import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Sidebar from './Component/Common/Sidebar';
import MainIndex from './Screen/MainIndex';
import {Switch, useNavigate, withRouter} from 'react-router-dom';
import AuthIndex from './Screen/AuthIndex';
import menu from '../src/Component/Data/Menu/Menu.json';
import {AuthProvider} from './Contexts/AuthContext';
import {ToastProvider} from './Contexts/ToastContext';

function App(props) {
    const [menuData, setMenuData] = useState([...menu.menu]);
    const navigate = useNavigate();
    const savedThemeColor = localStorage.getItem("themeColor");
    const themeColor = !(savedThemeColor === null) ? savedThemeColor : "cyan";
    const savedIsSidebarMini = localStorage.getItem("isSideBarMini");
    const initIsSidebarMini = !(savedIsSidebarMini === null) ? savedIsSidebarMini === "true" : false;

    var baseUrl = process.env.PUBLIC_URL
    const activekey = () => {
        var res = window.location.pathname
        var baseUrl = process.env.PUBLIC_URL
        baseUrl = baseUrl.split("/");
        res = res.split("/");
        res = res.length > 0 ? res[baseUrl.length] : "/";
        res = res ? "/" + res : "/";
        ;
        const activeKey1 = res;
        if (activeKey1 === '/') {
            return '/home'
        } else {
            return activeKey1
        }
    }
    if (activekey() === '/home' || activekey() === '/404page') {
        return (
            <div id="cryptoon-layout" className={'theme-' + themeColor}>
                <ToastProvider>
                    <AuthProvider>
                        <AuthIndex/>
                    </AuthProvider>
                </ToastProvider>
            </div>
        );
    }
    const GotoChangeMenu = (val) => {
        // props.history.push("dashboard");
        navigate(baseUrl + '/')
        setMenuData([...menu.menu]);
    }

    // console.log(activekey())
    return (

        <div id='cryptoon-layout' className={'theme-' + themeColor}>
            <ToastProvider>
                <AuthProvider>
                    <Sidebar
                        activekey={activekey()}
                        menuData={menuData}
                        GotoChangeMenu={(val) => {
                            GotoChangeMenu(val)
                        }}
                        initIsSidebarMini={initIsSidebarMini}
                    />
                    <MainIndex activekey={activekey()}
                               GotoChangeMenu={(val) => {
                                   GotoChangeMenu(val)
                               }}
                    />
                </AuthProvider>
            </ToastProvider>
        </div>
    );
}

const mapStateToProps = ({Mainreducer}) => ({
    Mainreducer
})

export default connect(mapStateToProps, {})(App);
