import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { useAuth } from '../../Contexts/AuthContext';
import { useToast } from '../../Contexts/ToastContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const SignOut = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const { setIsAuthenticated } = useAuth();

    const handleSignOut = async () => {
        try {
            if (process.env.REACT_APP_ENV === 'development') { console.log('Signing out...'); }
            await signOut();
            if (process.env.REACT_APP_ENV === 'development') { console.log('Sign out successful, updating auth state...'); }
            addToast('You have been successfully signed out. Good job being responsible!', {
                autoHide: true,
                delay: 15000,
                color: 'success'
            });
            setIsAuthenticated(false);
            if (process.env.REACT_APP_ENV === 'development') { console.log('Auth state updated, navigating to home...'); }
            navigate('/');
        } catch (error) {
            addToast('Error signing out. Please try again.', { autoHide: true, delay: 15000, color: 'danger' });
            if (process.env.REACT_APP_ENV === 'development') { console.error('Error signing out:', error); }
        }
    };

    return (
        <li>
            <Link to="#" className="m-link" onClick={handleSignOut}>
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                <span>Sign Out</span>
            </Link>
        </li>
    );
};

export default SignOut;
