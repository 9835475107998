import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Routes, useNavigate } from 'react-router-dom';
import { onModalOpen, onChangeDarkMode, onChangeHighcontrast } from '../Redux/Actions/Actions';
import Header from '../Component/Common/Header';
import SettingsModal from '../Component/Common/SettingsModal';
import Home from './Home';
import Profile from './UserProfile';
import ErrorPage from "../Component/Auth/ErrorPage";

function MainIndex(props) {
    const { modalopen, darkMode, highcontrast } = props.Mainreducer;
    const { activekey, GotoChangeMenu } = props;
    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;
    return (
        <div className='main px-lg-4 px-md-4'>
            <Header
                onModalOpen={(val) => { props.onModalOpen(true) }}
                GotoChangeMenu={(val) => { GotoChangeMenu(val) }}
            />
            <SettingsModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => { props.onModalOpen(false) }}
                onChangeDarkMode={() => { props.onChangeDarkMode(darkMode === 'dark' ? 'light' : 'dark') }}
                onChangeHighcontrast={() => { props.onChangeHighcontrast(highcontrast === 'high-contrast' ? 'light' : 'high-contrast') }}
            />
            <div className="body d-flex py-3 ">
                <Routes>
                    <Route path='/home' element={<Home />} />
                    <Route path='/profile/*' element={<Profile />} />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
        </div>
    )
}
const mapStateToProps = ({ Mainreducer }) => ({
    Mainreducer
})
export default connect(mapStateToProps, {
    onModalOpen,
    onChangeDarkMode,
    onChangeHighcontrast
})(MainIndex);