import React, { useState, useEffect, useRef } from "react";
import { useAuth } from '../../Contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import api from '../Common/axiosSetup';

function Login() {
    const navigate = useNavigate();
    const { isAuthenticated, authenticatedEmail, checkAuthState, setIsAuthenticated } = useAuth();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState('EMAIL'); // Steps: EMAIL, OTP
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    const inputRefs = useRef([]);

    useEffect(() => {
        checkAuthState().finally(() => setAuthCheckComplete(true));
    }, [checkAuthState]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, otp.length);
    }, [otp]);

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const allowedDomains = ['woodlanddc.com', 'flaresoft.solutions', 'djw.email'];
        const domain = email.split('@')[1];
        return emailPattern.test(email) && allowedDomains.includes(domain);
    };

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        try {
            const response = await api.post(`/api/auth/request`, { email });

            setCurrentStep('OTP');
            setSuccess('One time login request successful! Check your email for your OTP or magic link to log in. If you don’t see anything, remember to check your spam folder. This one time password is valid for 15 minutes.');
        } catch (error) {
            setError('An error occurred during your login request. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Simulate OTP verification and login
            // Replace this with the actual API call to verify OTP
            setIsAuthenticated(true);
            navigate('/dashboard');
        } catch (error) {
            setError('An error occurred during OTP verification. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (index, event) => {
        const newOtp = [...otp];
        newOtp[index] = event.target.value.slice(0, 1);  // Enforce single character
        setOtp(newOtp);

        // Move to next input if the input is filled and not the last one
        if (event.target.value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && otp[index] === '') {
            // Focus previous input if current is empty and backspace is pressed
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text').slice(0, 6); // Get first 6 characters
        const newOtp = paste.split('');
        setOtp(newOtp);

        const nextInputIndex = newOtp.length < 6 ? newOtp.length : 5;
        if (inputRefs.current[nextInputIndex]) {
            inputRefs.current[nextInputIndex].focus();
        }

        event.preventDefault(); // Stop default paste action
    };

    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Woodland OT - OT Hub</h1>
                                <h5>Access your Woodland OT services below.</h5>
                                <span className="text-muted">
                                    Simply enter your email, and we'll send you a secure one-time password (OTP) or magic link to access the system.
                                </span>
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        {success && <div className="alert alert-success">{success}</div>}
                                        {authCheckComplete ? (
                                            isAuthenticated ? (
                                                <div>
                                                    <p>You are already signed in as {authenticatedEmail}. Click below to
                                                        continue to your dashboard.</p>
                                                    <button onClick={() => navigate('/dashboard')}
                                                            className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                                                        Continue
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    {currentStep === 'EMAIL' && (
                                                        <form onSubmit={handleEmailSubmit}>
                                                            <div className="mb-3">
                                                                <label className="form-label fs-6">Email address</label>
                                                                <input type="email" className="form-control"
                                                                       value={email}
                                                                       onChange={e => setEmail(e.target.value)}
                                                                       required/>
                                                            </div>
                                                            <button type="submit"
                                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                                                                    disabled={loading}>
                                                                {loading ? "Loading..." : "Request Access"}
                                                            </button>
                                                        </form>
                                                    )}

                                                    {currentStep === 'OTP' && (
                                                        <form onSubmit={handleOtpSubmit}>
                                                            <div className="mb-3">
                                                                <label className="form-label fs-6">Enter OTP</label>
                                                                <div className="d-flex justify-content-between mb-4">
                                                                    {otp.map((value, index) => (
                                                                        <input
                                                                            key={index}
                                                                            ref={(el) => inputRefs.current[index] = el}
                                                                            type="text"
                                                                            className="form-control form-control-lg text-center"
                                                                            style={{width: "12%"}}
                                                                            maxLength="1"
                                                                            value={value}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                                            onPaste={handlePaste}
                                                                            required
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <button type="submit"
                                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                                                                    disabled={loading}>
                                                                {loading ? "Loading..." : "Log In"}
                                                            </button>
                                                        </form>
                                                    )}
                                                </>
                                            )
                                        ) : (
                                            <div className="d-flex justify-content-center mt-4">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
