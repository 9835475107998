import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import UserProfileOverview from './UserProfileOverview';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ConfigureTOTP from './ConfigureTOTP';
import UserActivity from './UserActivity';
import ErrorPage from '../Auth/ErrorPage';

const UserProfileContent = () => (
    <div className="user-profile-content">
        <Routes>
            <Route path="/" element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<UserProfileOverview />} />
            <Route path="change-email" element={<ChangeEmail />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="configure-totp" element={<ConfigureTOTP />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    </div>
);

export default UserProfileContent;
