import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import logoImage from '../assets/images/base_logo_transparent_background.png';
import heroImage from '../assets/images/vecteezy_blue-circuit-board-background-cloud-network-solution.jpg';
import vtScadaLogo from '../assets/images/vtscada.png';


function Home() {
    return (
        <Container fluid className="px-0">
            {/* Logo and Welcome Section */}
            <Row noGutters className="align-items-center">
                <Col className="text-center py-5">
                    <img src={logoImage} alt="SCADASwarm Logo" className="home-logo img-fluid"/>
                    <h3 className="mt-4 mb-2 text-primary display-6 fw-bold"
                        style={{letterSpacing: '1px', textShadow: '2px 2px 8px rgba(0,0,0,0.6)'}}>
                        Transforming SCADA with Cloud Innovation
                    </h3>
                </Col>
            </Row>

            {/* Hero Image Section */}
            <Row noGutters className="align-items-center">
                <Col>
                    <img src={heroImage} alt="Hero Background" className="img-fluid" style={{
                        width: '100%',
                        height: '35vh', // Adjust height here to control the thickness of the hero section
                        objectFit: 'cover', // Ensures the image covers the area and is not stretched
                        objectPosition: 'center', // Adjust this to control how the image is framed
                        borderRadius: '20px' // Adds rounded corners to the image
                    }} />
                </Col>
            </Row>

            {/* Mission and Vision Section */}
            <Row className="my-5 justify-content-center">
                <Col md={8}>
                    <Card className="text-center p-4">
                        <Card.Body>
                            <Card.Title>Our Mission</Card.Title>
                            <Card.Text>To provide robust and scalable SCADA infrastructure, enabling businesses to enhance control and efficiency in their operations.</Card.Text>
                            <hr className="mx-0" style={{width: '100%', height: '2px'}}/>
                            <Card.Title>Our Vision</Card.Title>
                            <Card.Text>To set the standard for cloud-based SCADA systems, fostering advanced automation and operational excellence across industries.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Key Features Section */}
            <Row className="my-5">
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Body>
                            <Card.Title className="font-weight-bold">Automated Deployment</Card.Title>
                            <hr className="mx-0" style={{width: '100%', height: '2px'}}/>
                            <Card.Text>Deploy SCADA systems automatically, reducing setup times and technical barriers.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Body>
                            <Card.Title className="font-weight-bold">Enhanced Security</Card.Title>
                            <hr className="mx-0" style={{width: '100%', height: '2px'}}/>
                            <Card.Text>Employ cutting-edge security measures to ensure data integrity and safety.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Body>
                            <Card.Title className="font-weight-bold">Full Customization</Card.Title>
                            <hr className="mx-0" style={{width: '100%', height: '2px'}}/>
                            <Card.Text>Customize every aspect of your SCADA systems to fit your specific needs.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* VTScada Affiliation Section */}
            <Row className="mb-5">
                <Col>
                    <Card className="w-100">
                        <Card.Body className="text-left">
                                <Card.Title className="font-weight-bold">Exclusively a VTScada Platform</Card.Title>
                                <hr className="mx-0" style={{ width: '100%', height: '2px' }} />
                                <Card.Text>
                                    SCADASwarm offers a dedicated infrastructure for deploying VTScada applications, providing the essential groundwork for your SCADA system. By exclusively supporting VTScada, we ensure access to a leading platform known for its robustness and advanced security features. This enables our clients to develop and manage their SCADA applications with confidence, leveraging our reliable and scalable infrastructure tailored to meet the demands of modern industrial automation.
                                </Card.Text>
                            <img src={vtScadaLogo} alt="VTScada Logo" className="img-fluid mt-3 mx-auto d-block" style={{ maxWidth: '15%', height: 'auto' }} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Call to Action Button */}
            <Row className="text-center mb-5">
                <Col>
                    <button className="btn btn-primary">Explore Our Plans</button>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
