import React, {createContext, useContext, useState, useEffect, useCallback, useRef} from 'react';
import {fetchAuthSession} from 'aws-amplify/auth';
import {useToast} from './ToastContext';
import { useNavigate, Link } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authenticatedEmail, setAuthenticatedEmail] = useState('');
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    const {addToast} = useToast();
    const prevIsAuthenticated = useRef(isAuthenticated);
    const navigate = useNavigate();

    const handleSessionTimeout = useCallback(() => {
        addToast(
            <span>
                Your session has timed out. Please sign in again.
            </span>,
            {
                requireAck: true,
                autoHide: false,
                color: 'warning'
            }
        );
        navigate('/');
    }, [addToast]);

    const checkAuthState = useCallback(async () => {
        try {
            if (process.env.REACT_APP_ENV === 'development') { console.log('Checking auth state...'); }
            const session = await fetchAuthSession({forceRefresh: true});
            const idToken = session.tokens.idToken;
            if (idToken) {
                setAuthenticatedEmail(idToken.payload.email);
                setIsAuthenticated(true);
                localStorage.setItem('idToken', idToken);

                // Log session information in development mode
                if (process.env.REACT_APP_ENV === 'development') {
                    console.log('ID Token:', idToken);
                    console.log('Token expiration:', new Date(idToken.payload.exp * 1000));
                    console.log('Token issued at:', new Date(idToken.payload.iat * 1000));
                }
            } else {
                throw new Error('No valid token found');
            }
        } catch (error) {
            localStorage.removeItem('idToken');
            if (prevIsAuthenticated.current) {
                handleSessionTimeout();
            }
            setIsAuthenticated(false);
            setAuthenticatedEmail('');

            // Log error in development mode
            if (process.env.REACT_APP_ENV === 'development') {
                console.error('Authentication error:', error);
            }
        } finally {
            setAuthCheckComplete(true);
            if (process.env.REACT_APP_ENV === 'development') { console.log('Auth check complete.'); }
        }
    }, [handleSessionTimeout]);

    useEffect(() => {
        // Initial auth state check
        checkAuthState();
    }, [checkAuthState]);

    useEffect(() => {
        // Set up interval to check auth state only if the user is authenticated
        if (isAuthenticated) {
            const interval = setInterval(() => {
                checkAuthState();
            }, 60000); // Check every 60 seconds

            return () => clearInterval(interval); // Cleanup interval on component unmount
        }
    }, [isAuthenticated, checkAuthState]);

    useEffect(() => {
        prevIsAuthenticated.current = isAuthenticated; // Update the previous state
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{isAuthenticated, authenticatedEmail, authCheckComplete, checkAuthState, setIsAuthenticated}}>
            {children}
        </AuthContext.Provider>
    );
};
