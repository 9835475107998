import React, {useState, useEffect} from 'react';
import {useAuth} from '../../Contexts/AuthContext';
import SignOut from '../Auth/SignOut';
import {useToast} from '../../Contexts/ToastContext';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faSignInAlt, faHomeAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import SCADASwarmLogoBlack from "../../assets/images/base_textlogo_dark.svg";
import SCADASwarmLogoWhite from "../../assets/images/base_textlogo_light.svg";
import SCADASwarmSmallLogoBlack from "../../assets/images/logo_small_black.svg";
import SCADASwarmSmallLogoWhite from "../../assets/images/logo_small_white.svg";

// Create a map of icons
const icons = {
    "faUser": faUser,
    "faSignInAlt": faSignInAlt,
    "faHomeAlt": faHomeAlt,
    "faSignOutAlt": faSignOutAlt
};

function Sidebar(props) {
    const {activekey, menuData, GotoChangeMenu, initIsSidebarMini} = props;
    const {isAuthenticated, authCheckComplete, authenticatedEmail} = useAuth();
    const {addToast} = useToast();
    const [isSidebarMini, setIsSidebarMini] = useState(initIsSidebarMini);

    const ChangeSideBar = (setVal) => {
        localStorage.setItem("isSideBarMini", !setVal);
        setIsSidebarMini(!setVal)
    }

    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;

    const getLogo = () => {
        const mode = document.documentElement.getAttribute("data-theme");
        if (isSidebarMini) {
            return mode === 'light' ? SCADASwarmSmallLogoBlack : SCADASwarmSmallLogoWhite;
        } else {
            return mode === 'light' ? SCADASwarmLogoBlack : SCADASwarmLogoWhite;
        }
    }

    const filteredMenuData = menuData.filter(item =>
        item.authenticated === null ||
        item.authenticated === isAuthenticated
    );

    return (<div id="mainsidemenu"
                 className={`sidebar py-2 py-md-2 me-0 border-end ${isSidebarMini === true ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <Link to="/" className="mb-0 brand-icon">
                <span className="logo-icon">
                    <img className="logo-image" src={getLogo()} alt=""/>
                </span>
                </Link>
                <ul className={`menu-list flex-grow-1 ${isSidebarMini ? 'mt-5' : 'mt-4'} px-1`}>
                    {authCheckComplete && filteredMenuData.map((item, i) => (
                        <li key={i}>
                            <Link to={"/" + item.routerLink[0]}
                                  className={`m-link ${("/" + item.routerLink[0] === activekey) ? "active" : ""}`}>
                                <FontAwesomeIcon icon={icons[item.icon]} className="me-2"/>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                    {isAuthenticated && <SignOut />}
                </ul>
                <button type="button" className="btn btn-link sidebar-mini-btn text-muted" onClick={() => {
                    ChangeSideBar(isSidebarMini)
                }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    )
}

export default Sidebar;