import React, {useState, useEffect, useRef} from 'react';
import {
    getCurrentUser,
    updateUserAttributes,
    sendUserAttributeVerificationCode,
    confirmUserAttribute,
    fetchUserAttributes,
} from 'aws-amplify/auth';
import {useToast} from '../../Contexts/ToastContext';
import {Card, Form, Button, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

const ChangeEmail = () => {
    const [currentEmail, setCurrentEmail] = useState('****');
    const [newEmail, setNewEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
    const [isEditing, setIsEditing] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const {addToast} = useToast();
    const inputRefs = useRef([]);

    useEffect(() => {
        let isMounted = true;

        const fetchUserData = async () => {
            try {
                const user = await getCurrentUser();
                const attributes = await fetchUserAttributes(user);
                if (process.env.REACT_APP_ENV === 'development') {
                    console.log('User attributes:', attributes);
                }

                if (isMounted && attributes && attributes.email) {
                    setCurrentEmail(attributes.email);
                }
            } catch (error) {
                if (isMounted) {
                    addToast('Error fetching user data. Please try again later.', {
                        autoHide: true,
                        delay: 15000,
                        color: 'danger',
                        requireAck: true
                    });
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();

        return () => {
            isMounted = false;
        };
    }, [addToast]);

    useEffect(() => {
        inputRefs.current = verificationCode.map((_, i) => inputRefs.current[i] ?? React.createRef());
    }, [verificationCode]);

    const handleEdit = () => {
        setIsEditing(true);
        setNewEmail(currentEmail);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNewEmail('');
    };

    const handleSave = async () => {
        try {
            await updateUserAttributes({
                userAttributes: {
                    email: newEmail
                }
            });
            addToast('Verification code sent to new email address. Please verify to complete the update.', {
                autoHide: true,
                delay: 15000,
                color: 'info'
            });
            setIsVerifying(true);
        } catch (error) {
            addToast('Error updating email. Please try again later.', {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error updating email:', error);
        }
    };

    const handleVerify = async (event) => {
        if (event) event.preventDefault();

        try {
            const code = verificationCode.join('');
            await confirmUserAttribute({ userAttributeKey: 'email' , confirmationCode: code });
            setCurrentEmail(newEmail);
            setIsEditing(false);
            setIsVerifying(false);
            addToast('Email updated successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success'
            });
        } catch (error) {
            addToast(`Error modifying user data: [${error.message}] Contact support@scadaswarm.io if this error persists.`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error verifying email:', error);
        }
    };

    const handleInputChange = (index, event) => {
        const newInputs = [...verificationCode];
        newInputs[index] = event.target.value.slice(0, 1);
        setVerificationCode(newInputs);

        if (event.target.value && index < verificationCode.length - 1) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && verificationCode[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].current.focus();
            }
        }
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text').slice(0, 6);
        const newInputs = paste.split('');
        setVerificationCode(newInputs);

        const nextInputIndex = newInputs.length < verificationCode.length ? newInputs.length : verificationCode.length - 1;
        if (inputRefs.current[nextInputIndex]) {
            inputRefs.current[nextInputIndex].current.focus();
        }

        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">Change Email</Card.Title>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formCurrentEmail">
                        <Form.Label>Current Email address</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="email"
                                value={isEditing ? newEmail : currentEmail}
                                readOnly={!isEditing}
                                onChange={(e) => setNewEmail(e.target.value)}
                                className={isEditing ? 'editing-mode' : ''}
                            />
                            {isEditing ? (
                                <>
                                    <Button variant="outline-secondary" onClick={handleSave}>
                                        <FontAwesomeIcon icon={faSave}/>
                                    </Button>
                                    <Button variant="outline-secondary" onClick={handleCancel}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </Button>
                                </>
                            ) : (
                                <Button variant="outline-secondary" onClick={handleEdit}>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </Button>
                            )}
                        </InputGroup>
                    </Form.Group>
                    {isVerifying && (
                        <Form.Group className="mb-3" controlId="formVerificationCode">
                            <Form.Label>Verification Code</Form.Label>
                            <InputGroup>
                                <div className="d-flex justify-content-between mb-4">
                                    {verificationCode.map((value, index) => (
                                        <Form.Control
                                            key={index}
                                            ref={inputRefs.current[index]}
                                            type="text"
                                            className="form-control form-control-lg text-center"
                                            style={{width: '12%'}}
                                            maxLength="1"
                                            value={value}
                                            onChange={(e) => handleInputChange(index, e)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            onPaste={handlePaste}
                                            required
                                        />
                                    ))}
                                </div>
                                <Button variant="outline-secondary" onClick={handleVerify}>
                                    Verify
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ChangeEmail;
