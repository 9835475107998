import React, { useState, useEffect, useRef } from 'react';
import { getCurrentUser, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const UserProfileOverview = () => {
    const [userId, setUserId] = useState('****');
    const [userEmail, setUserEmail] = useState('****');
    const [userOrganization, setUserOrganization] = useState('****');
    const [isEditing, setIsEditing] = useState(false);
    const [newOrganization, setNewOrganization] = useState('');
    const { addToast } = useToast();
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return;
        isMounted.current = true;

        const fetchUserData = async () => {
            try {
                const user = await getCurrentUser();
                const attributes = await fetchUserAttributes(user);
                if (process.env.REACT_APP_ENV === 'development') { console.log('User attributes:', attributes); }

                const email = attributes.email || '****';
                const organization = attributes['custom:organization'] || '';
                const id = attributes.sub || '****';

                setUserId(id);
                setUserEmail(email);
                setUserOrganization(organization);
                setNewOrganization(organization);
            } catch (error) {
                addToast('Error fetching user data. Please try again later.', {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true
                });
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [addToast]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            await updateUserAttributes({
                userAttributes: {
                    'custom:organization': newOrganization
                },
            });
            setUserOrganization(newOrganization);
            setIsEditing(false);
            addToast('Organization updated successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success'
            });
        } catch (error) {
            addToast(`Error modifying user data: [${error.message}] Contact support@scadaswarm.io if this error persists.`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error updating organization:', error);
        }
    };

    const handleCancel = () => {
        setNewOrganization(userOrganization);
        setIsEditing(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">User Profile Overview</Card.Title>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formUserId">
                        <Form.Label>User ID</Form.Label>
                        <Form.Control type="text" value={userId} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formUserEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" value={userEmail} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formUserOrganization">
                        <Form.Label>Organization</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                value={isEditing ? newOrganization : userOrganization}
                                readOnly={!isEditing}
                                onChange={(e) => setNewOrganization(e.target.value)}
                                className={`form-control ${isEditing ? 'editing-mode' : ''}`}
                            />
                            {isEditing ? (
                                <>
                                    <Button variant="outline-secondary" onClick={handleSave}>
                                        <FontAwesomeIcon icon={faSave} />
                                    </Button>
                                    <Button variant="outline-secondary" onClick={handleCancel}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </>
                            ) : (
                                <Button variant="outline-secondary" onClick={handleEdit}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            )}
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default UserProfileOverview;
