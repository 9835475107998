import React, { useState, useRef, useEffect } from 'react';
import {fetchUserAttributes, getCurrentUser, setUpTOTP, verifyTOTPSetup} from 'aws-amplify/auth';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Button, Modal } from 'react-bootstrap';

const ConfigureTOTP = () => {
    const [inputs, setInputs] = useState(Array(6).fill(''));
    const [setupUri, setSetupUri] = useState('');
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [totpSetupStarted, setTotpSetupStarted] = useState(false);
    const { addToast } = useToast();
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current = inputs.map((_, i) => inputRefs.current[i] ?? React.createRef());
    }, [inputs]);

    useEffect(() => {
        if (inputRefs.current[0] && inputRefs.current[0].current) {
            inputRefs.current[0].current.focus();
        }
    }, [totpSetupStarted]);

    useEffect(() => {
        const allFilled = inputs.every(input => input !== '');
        if (allFilled) {
            handleSubmit();
        }
    }, [inputs]);

    const handleInputChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index] = event.target.value.slice(0, 1);
        setInputs(newInputs);

        if (event.target.value && index < inputs.length - 1) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && inputs[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].current.focus();
            }
        }
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text').slice(0, 6);
        const newInputs = paste.split('');
        setInputs(newInputs);

        const nextInputIndex = newInputs.length < inputs.length ? newInputs.length : inputs.length - 1;
        if (inputRefs.current[nextInputIndex]) {
            inputRefs.current[nextInputIndex].current.focus();
        }

        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();

        setLoading(true);

        const otpCode = inputs.join('');

        try {
            const user = await getCurrentUser();
            await verifyTOTPSetup({code: otpCode});
            addToast('TOTP configured successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success'
            });
            setTotpSetupStarted(false);
        } catch (error) {
            addToast(`Error verifying TOTP: [${error.message}] Contact support@scadaswarm.io if this error persists.`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error verifying TOTP:', error);
        } finally {
            setLoading(false);
        }
    };

    const startTotpSetup = async () => {
        try {
            const user = await getCurrentUser();
            const attributes = await fetchUserAttributes(user);
            const totpSetupDetails = await setUpTOTP();
            if (process.env.REACT_APP_ENV === 'development') { console.log('setUpTOTP Return:', totpSetupDetails); }
            const appName = 'SCADASwarm';
            const secret = totpSetupDetails.sharedSecret;
            const email = attributes.email;
            const uri = `otpauth://totp/${encodeURIComponent(appName)}:${encodeURIComponent(email)}?secret=${secret}&issuer=${encodeURIComponent(appName)}`;
            setSetupUri(uri);
            setTotpSetupStarted(true);
        } catch (error) {
            addToast(`Error setting up TOTP: [${error.message}] Contact support@scadaswarm.io if this error persists.`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error setting up TOTP:', error);
        }
    };

    const handleConfirmSetup = () => {
        setShowConfirmation(true);
    };

    const handleCancelSetup = () => {
        setShowConfirmation(false);
    };

    const handleConfirmSetupYes = async () => {
        setShowConfirmation(false);
        await startTotpSetup();
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">Configure TOTP</Card.Title>
                {!totpSetupStarted ? (
                    <>
                        <div className="alert alert-info" role="alert">
                            To enhance your account security, please set up Multi-Factor Authentication (MFA). You cannot disable TOTP, so ensure you transfer to the new device before proceeding.
                        </div>
                        <Button variant="primary" onClick={handleConfirmSetup}>
                            Set up TOTP
                        </Button>
                    </>
                ) : (
                    <>
                        <div className="mb-3">
                            <p>Scan the QR code with your authenticator app and enter the code generated.</p>
                            {setupUri && <img src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(setupUri)}`} alt="QR Code" />}
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <h5 className="mt-4 mb-2">TOTP Code</h5>
                            <div className="d-flex justify-content-between mb-4">
                                {inputs.map((value, index) => (
                                    <input
                                        key={index}
                                        ref={inputRefs.current[index]}
                                        type="text"
                                        className="form-control form-control-lg text-center"
                                        style={{ width: '12%' }}
                                        maxLength="1"
                                        value={value}
                                        onChange={(e) => handleInputChange(index, e)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        onPaste={handlePaste}
                                        required
                                    />
                                ))}
                            </div>
                            <Button type="submit" disabled={loading} className="w-100">
                                {loading ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </>
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Form>
                    </>
                )}
            </Card.Body>
            <Modal show={showConfirmation} onHide={handleCancelSetup}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm TOTP Setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to set up a new TOTP device?</p>
                    <span className="text-muted text-danger">NOTE: You cannot disable MFA in your scadaswarm account.</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelSetup}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleConfirmSetupYes}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default ConfigureTOTP;