import axios from 'axios';

// Create an Axios instance if you don't already have one
const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
        'x-env': process.env.REACT_APP_ENV
    }
});

if (process.env.REACT_APP_ENV === 'development') {
// Request interceptor
    api.interceptors.request.use(request => {
        console.log('Starting Request', JSON.stringify(request, null, 2));
        return request;
    }, error => {
        console.log('Request Error:', error);
        return Promise.reject(error);
    });

// Response interceptor
    api.interceptors.response.use(response => {
        console.log('Response:', JSON.stringify(response, null, 2));
        return response;
    }, error => {
        console.log('Response Error:', error);
        return Promise.reject(error);
    });
}

export default api;
