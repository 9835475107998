import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import UserProfileMenu from '../Component/Profile/UserProfileMenu';
import UserProfileContent from '../Component/Profile/UserProfileContent';

const UserProfile = () => (
    <div className="user-profile-page">
        <div className="user-profile-container">
            <Row>
                <Col xs={12} md={4} className="user-profile-left">
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>User Profile Navigation</Card.Title>
                            <UserProfileMenu />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={8} className="user-profile-right">
                    <Card className="mb-4">
                        <Card.Body>
                            <UserProfileContent />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
);

export default UserProfile;
